<template>
	<div class="transfer-details">
		<w-navTab titleText="捐赠简介"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="head-top">
				<img :src="transfer.picture" alt="" />
			</div>
			<div class="nav">
				<w-tab :active="active" :data="loveData" @click="onTab"></w-tab>
			</div>
			<template v-if="active == 0">
				<div class="Delivery-middle">
					<div class="middle">
						<div class="middle-top">
							<van-circle v-model="currentRate"
								:rate="progressWidth(transfer.donate_integral,transfer.need_integral)"
								layer-color="#ebedf0" size="140px" />
							<div class="middle-top-box">
								<div class="middle-top-box-title">爱心积分</div>
								<div class="middle-top-box-bewrite">{{ transfer.donate_integral }}</div>
								<div class="middle-top-box-text">已完成
									{{progressWidth(transfer.donate_integral,transfer.need_integral)}}%
								</div>
							</div>
						</div>
						<div class="middle-amount">
							<div class="middle-amount-left">
								<div class="title">已筹集爱心积分</div>
								<div class="amount">
									{{ transfer.donate_integral == null ? '0' : transfer.donate_integral }}
								</div>
							</div>
							<div class="middle-amount-centre"></div>
							<div class="middle-amount-right">
								<div class="title">共需要爱心积分</div>
								<div class="amount">{{ transfer.need_integral }}</div>
							</div>
						</div>
						<div class="middle-amount-footer">
							该项目已有
							<span>{{ transfer.total_user }}</span>
							人捐赠
						</div>
					</div>
				</div>
				<div class="transfer-middle">
					<div class="middle-top">
						<div class="top-left">接受捐赠单位简介</div>
					</div>
					<div class="middle-item">
						<div class="middle-item-title">机构名称：</div>
						<div class="middle-item-text">{{ transfer.org_name }}</div>
					</div>
					<div class="middle-item">
						<div class="middle-item-title">联系电话：</div>
						<div class="middle-item-text">{{ transfer.org_telephone }}</div>
					</div>
					<div class="middle-item">
						<div class="middle-item-title">机构地址：</div>
						<div class="middle-item-text">{{ transfer.org_address }}</div>
					</div>
					<div class="middle-item">
						<div class="middle-item-title">机构简介：</div>
						<div class="middle-item-text">{{ transfer.org_intro }}</div>
					</div>
				</div>
				<div class="footer">
					<div class="middle-top">
						<div class="top-left">公益描述</div>
					</div>
					<div class="middle-bottom">{{ transfer.intro }}</div>
				</div>

				<div class="footer-btn" @click="illustrate">
					<van-button type="primary" round color="#3377FF" block @click="showPopup">我要捐赠</van-button>
				</div>

				<van-popup v-model="showPopup" position="bottom" :style="{ height: '70%' }">
					<div class="content">
						<div class="content-top">
							<div class="content-top-left">捐赠爱心值</div>
							<div class="content-top-right"
								v-if="transfer.need_integral - transfer.donate_integral != 0">
								还差<span>{{transfer.need_integral - transfer.donate_integral}}</span>爱心积分 </div>
							<div class="content-top-right" v-else>爱心筹集已完成</div>
						</div>
						<div class="content-middle">
							<div :class="[{ active: number === index }]" v-for="(item, index) in list"
								@click="onNumber(index)">{{ item.title }}</div>
						</div>
						<div class="content-middle-input" v-if="number == 3"><input type="number" placeholder="输入爱心值"
								v-model="integral" /></div>
						<div class="content-middle-input"><input placeholder="捐款人姓名/企业名称" v-model="company" /></div>
						<div class="title">捐赠须知</div>
						<div :class="['text', { 'over-hidden': !unfold }]" ref="textBox">
							<span ref="spanBox">项目募捐备案：根据《慈善法》规定，慈善组织通过互联网开展公开募捐，应当在国务院民政部门统一或者指定的慈善信息平台项目募捐备案</span>
						</div>
						<div class="skill-footer" @click="unfold = !unfold">{{ unfold ? '收起信息' : '展开信息' }}</div>
						<div class="head-picture">
							<img v-if="!unfold" src="@/assets/img/icon211.png" alt="" />
							<img v-else src="@/assets/img/icon212.png" alt="" />
						</div>
						<div class="content-btn">
							<div class="btn" @click="onDonate()">立即捐赠</div>
						</div>
					</div>
				</van-popup>
			</template>

			<template v-if="active == 1">
				<TransferList></TransferList>
			</template>
		</div>
	</div>
</template>

<script>
	import Transfer from '@/api/transfer';
	import Vant from '@/vendor/vant';
	import WTab from '@/components/Tab';
	import TransferList from '@/views/transfer/TransferList';
	import LoadingPage from '@/components/LoadingPage';
	import UserApi from '@/api/user';
	export default {
		name: 'TransferDetails',
		data() {
			return {
				active: 0,
				loveData: [{
						title: '捐赠简介'
					},
					{
						title: '捐赠记录'
					}
				],
				rate: 10,
				currentRate: 100,
				showPopup: false,
				unfold: false,
				list: [{
						title: '50',
						number: 50
					},
					{
						title: '100',
						number: 100
					},
					{
						title: '200',
						number: 200
					},
					{
						title: '自定义',
						number: ''
					}
				],
				number: 0,
				integral: 50,
				name: '',
				company: '',
				transfer: '',
				transferId: '',
				loadingPage: true,
				user: ""
			};
		},
		created() {
			this.transferId = this.$route.params.id;
			this.onTransfer()
			this.getUserInfo()
		},
		methods: {
			getUserInfo() {
				UserApi.getUserInfo()
					.then(result => {
						this.user = result.data
					})
					.catch(error => {});
			},
			onTransfer() {
				Transfer.donateDetail({
						fundraiser_id: this.transferId
					})
					.then(result => {
						this.transfer = result.data;
						this.loadingPage = false;
					})
					.catch(error => {});
			},
			progressWidth(current, total) {
				let progress = parseInt((current / total) * 100)
				return progress < 100 ? progress : 100;
			},
			onDonate() {
				let params = {
					fundraiser_id: this.transferId,
					integral: this.integral,
					nominal: this.company
				};
				let integral = this.transfer.donate_integral + this.integral
				Transfer.toDonate(params)
					.then(result => {
						Vant.Toast('捐赠成功');
						this.showPopup = false;
						this.onTransfer()
						this.getUserInfo()
					})
					.catch(error => {
						Vant.Toast(error.msg);
					});
			},
			onTab(index) {
				this.active = index;
			},
			illustrate() {
				this.showPopup = true;
			},
			onNumber(index) {
				this.number = index;
				this.integral = this.list[index].number;
			}
		},
		components: {
			WTab,
			TransferList,
			LoadingPage
		}
	};
</script>

<style lang="less" scoped>
	.transfer-details {
		.container {
			padding-bottom: 55px;

			.active {
				background: #3377ff;
				color: #fff !important;
				border: 0 !important;
			}

			.head-top {
				width: 100%;
				height: 200px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.nav {
				background: #f2f2f2;
				border-radius: 15px 15px 0px 0px;
				padding: 13px 10px 2px 10px;
				margin-top: -13px;
				position: relative;
				z-index: 2;
			}

			.Delivery-middle {
				padding: 10px;

				.middle {
					width: 100%;
					padding: 30px 0 10px;
					background-color: #fff;
					border-radius: 6px;

					.middle-top {
						text-align: center;
						margin-bottom: 20px;
						position: relative;

						.middle-top-box {
							text-align: center;
							position: absolute;
							width: 140px;
							height: 140px;
							top: 28px;
							left: 108px;

							.middle-top-box-title {
								font-size: 16px;
								line-height: 26px;
								color: #777777;
							}

							.middle-top-box-bewrite {
								font-size: 36px;
								line-height: 26px;
								color: #3377ff;
								padding: 6px 0;
							}

							.middle-top-box-text {
								font-size: 14px;
								line-height: 26px;
								color: #4292f6;
							}
						}

						/deep/.van-circle__hover {
							stroke: #ffb23f;
						}
					}

					.middle-amount {
						display: flex;
						justify-content: center;
						align-items: center;

						.middle-amount-left {
							padding: 0 20px;
						}

						.middle-amount-centre {
							width: 1px;
							height: 42px;
							background-color: #ddd;
						}

						.middle-amount-right {
							padding: 0 20px;
						}

						.title {
							font-size: 16px;
							line-height: 26px;
							text-align: center;
							color: #777;
						}

						.amount {
							font-size: 20px;
							line-height: 26px;
							text-align: center;
							color: #3377ff;
						}
					}

					.middle-amount-footer {
						font-size: 13px;
						line-height: 25px;
						text-align: center;
						color: #666;
						margin-top: 5px;

						span {
							color: #ff6969;
						}
					}
				}
			}

			// .footer-btn {
			// 	padding: 0 10px;
			// 	margin-bottom: 20px;
			// }


			.footer-btn {
				position: fixed;
				width: 100%;
				left: 0;
				bottom: 0;
				padding: 0 10px;
				margin-bottom: 20px;
				box-sizing: border-box;
			}

			.transfer-middle {
				background: #ffffff;
				border-radius: 6px;
				padding: 15px 10px;
				box-sizing: border-box;
				margin: 0 10px 10px 10px;

				.middle-top {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-bottom: 10px;
					border-bottom: 0.5px solid #f2f2f2;

					.top-left {
						font-size: 18px;
						line-height: 26px;
						color: #333;
						font-weight: bold;
					}
				}

				.middle-item {
					display: flex;
					justify-content: flex-start;
					margin-top: 10px;

					.middle-item-title {
						width: 90px;
						min-width: 90px;
						font-size: 16px;
						line-height: 26px;
						color: #333;

					}

					.middle-item-text {
						font-size: 16px;
						line-height: 26px;
						color: #666;
					}
				}
			}

			.footer {
				background: #ffffff;
				border-radius: 6px;
				padding: 15px 10px;
				box-sizing: border-box;
				margin: 0 10px 20px 10px;

				.middle-top {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-bottom: 10px;
					border-bottom: 0.5px solid #f2f2f2;

					.top-left {
						font-size: 18px;
						line-height: 26px;
						font-weight: bold;
						color: #333;
					}
				}

				.middle-bottom {
					font-size: 16px;
					line-height: 26px;
					color: #666;
					margin-top: 10px;
				}
			}

			/deep/.van-popup {
				border-radius: 20px 20px 0px 0px;
				overflow: hidden;
			}

			.content {
				padding: 15px 20px;
				box-sizing: border-box;
				height: 100%;
				overflow-y: auto;

				.skill-footer {
					width: 80px;
					font-size: 16px;
					line-height: 24px;
					color: #777;
					text-align: center;
					background-color: #fff;
					border: 1px solid #AAAAAA;
					border-radius: 4px;
					padding: 0 5px;
					margin: 0 auto;
					box-sizing: border-box;
				}

				.head-picture {
					width: 16px;
					height: 15px;
					margin-top: 5px;
					margin-left: 50%;
					transform: translateX(-50%);

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}


			.content-top {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 10px;
				border-bottom: 0.5px solid #f2f2f2;

				.content-top-left {
					font-size: 18px;
					line-height: 26px;
					color: #222;
				}

				.content-top-right {
					font-size: 14px;
					line-height: 26px;
					color: #777;

					span {
						color: #3377ff;
					}
				}
			}

			.content-middle {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 15px 0;

				div {
					width: 71px;
					height: 40px;
					border: 1px solid #aaa;
					border-radius: 6px;
					text-align: center;
					line-height: 40px;
					font-size: 20px;
					color: #3377ff;
				}
			}

			.content-middle-input {
				width: 100%;
				padding: 10px;
				border: 1px solid #aaa;
				box-sizing: border-box;
				border-radius: 6px;
				margin-bottom: 20px;
				overflow: hidden;

				input {
					width: 100%;
					border: 0;
				}
			}

			.title {
				font-size: 18px;
				line-height: 26px;
				color: #222;
				margin-bottom: 5px;
			}

			.text {
				font-size: 16px;
				line-height: 26px;
				color: #777;
			}

			.over-hidden {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				overflow: hidden;
			}

			.content-btn {
				padding: 0 10px;
				margin-top: 20px;

				.btn {
					width: 100%;
					padding: 11px 0;
					text-align: center;
					font-size: 18px;
					line-height: 26px;
					color: #fff;
					background: #3377FF;
					border-radius: 100px;
				}
			}
		}
	}
</style>
